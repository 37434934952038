<template>
  <v-row>
    <v-col v-if="!campaignHasTargets">
      {{ $t('campaign.targets.no_targets') }}
    </v-col>

    <v-col v-if="!targets.ipv6Target.accept" class="pa-1" cols="12" sm="4" lg="3">
      <target-card
        :title="$t('campaign.targets.ipv6_target')"
        :items="[{ name: $t('campaign.targets.ipv6_off') }]"
        :no-limits="noLimits"
        single-target
      />
    </v-col>

    <v-col v-if="targets.uniquesTarget.accept" class="pa-1" cols="12" sm="4" lg="3">
      <target-card
        :title="$t('campaign.uniques')"
        :items="[{ name: uniquesTargetLabel }]"
        :no-limits="noLimits"
        single-target
      />
    </v-col>

    <v-col v-if="!!targets.ipRangesTarget.ranges" class="pa-1" cols="12" sm="4" lg="3">
      <ip-range-card
        :title="`${$t('campaign.targets.ip_ranges')} ${blackOrWhiteListsIpRange}`"
        :ip-ranges="targets.ipRangesTarget.ranges"
        :no-limits="noLimits"
      />
    </v-col>

    <v-col v-if="campaign.cpaGoals.length" class="pa-1" cols="12" sm="4" lg="3">
      <cpa-goals-card :title="pricingModel" :cpa-goals="campaign.cpaGoals" :limit="3" :no-limits="noLimits" />
    </v-col>

    <v-col v-if="targets.countryTarget.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card :title="$t('campaign.targets.countries')" :items="targets.countryTarget" :no-limits="noLimits" />
    </v-col>

    <v-col v-if="targets.regionTarget.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card :title="$t('campaign.targets.regions')" :items="targets.regionTarget" :no-limits="noLimits" />
    </v-col>

    <v-col v-if="targets.cityTarget.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card :title="$t('campaign.targets.cities')" :items="targets.cityTarget" :no-limits="noLimits" />
    </v-col>

    <v-col v-if="browserTarget.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card
        :title="campaign.targets.browserTarget.accept
          ? $t('campaign.targets.whitelist_browsers')
          : $t('campaign.targets.blacklist_browsers')"
        :items="browserTarget"
        :no-limits="noLimits"
      />
    </v-col>
    <v-col v-if="targets.trafficTypeTarget.name" class="pa-1" cols="12" sm="4" lg="3">
      <target-card
        :title="$t('campaign.targets.device_type')"
        :items="[targets.trafficTypeTarget]"
        :no-limits="noLimits"
        single-target
      />
    </v-col>

    <v-col v-if="targets.languageTarget.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card :title="$t('campaign.targets.languages')" :items="targets.languageTarget" :no-limits="noLimits" />
    </v-col>

    <v-col v-if="operatingSystemTarget.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card :title="$t('campaign.targets.os')" :items="operatingSystemTarget" :no-limits="noLimits" />
    </v-col>

    <v-col v-if="targets.placementTypeTarget.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card
        :title="$t('campaign.targets.placement_types')"
        :items="targets.placementTypeTarget"
        :no-limits="noLimits"
      />
    </v-col>

    <v-col v-if="targets.ip2LocationTypeTarget.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card title="IP2 Location Type" :items="targets.ip2LocationTypeTarget" :no-limits="noLimits" />
    </v-col>

    <v-col v-if="targets.deviceVendorTarget.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card
        :title="$t('campaign.targets.device_vendors')"
        :items="targets.deviceVendorTarget"
        :no-limits="noLimits"
      />
    </v-col>

    <v-col v-if="targets.deviceModelTarget.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card
        :title="$t('campaign.targets.device_models')"
        :items="targets.deviceModelTarget"
        :no-limits="noLimits"
      />
    </v-col>

    <v-col v-if="carrierTargetType" class="pa-1" cols="12" sm="4" lg="3">
      <target-card
        :title="$t('campaign.targets.connection_type')"
        :items="[carrierTargetType]"
        :no-limits="noLimits"
        single-target
      />
    </v-col>

    <v-col v-if="targets.providersTarget.selectedProviders.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card
        :title="$t('campaign.targets.providers')"
        :items="targets.providersTarget.selectedProviders"
        :no-limits="noLimits"
      />
    </v-col>

    <v-col v-if="targets.carrierTarget.carriers.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card
        :title="$t('campaign.targets.carriers')"
        :items="targets.carrierTarget.carriers"
        :no-limits="noLimits"
      />
    </v-col>

    <v-col v-if="siteTargetLabel" class="pa-1" cols="12" sm="4" lg="3">
      <sites-card
        :title="siteTargetLabel"
        :sites="targets.siteTarget.sites"
        :campaign="campaign"
        :premium-sites-is-all="premiumSitesIsAll"
        :no-limits="noLimits"
      />
    </v-col>

    <v-col v-if="spotTarget.length" class="pa-1" cols="12" md="4" lg="3">
      <target-card
        :title="campaign.targets.spotTarget.accept ? $t('campaign.targets.whitelist_spots') : $t('campaign.targets.blacklist_spots')"
        :items="spotTarget"
        :no-limits="noLimits"
      />
    </v-col>

    <v-col v-if="targets.mobileAppTarget.mobileApps.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card :title="mobileAppTargetLabel" :items="targets.mobileAppTarget.mobileApps" :no-limits="noLimits" />
    </v-col>

    <v-col v-if="targets.categoryGroupTarget.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card
        :title="$t('campaign.targets.categories_group')"
        :items="targets.categoryGroupTarget"
        :no-limits="noLimits"
      />
    </v-col>

    <v-col v-if="targets.categoryTarget.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card :title="$t('campaign.targets.categories')" :items="targets.categoryTarget" :no-limits="noLimits" />
    </v-col>

    <v-col v-if="targets.inPageTrafficTarget" class="pa-1" cols="12" sm="4" lg="3">
      <target-card
        :title="$t('campaign.targets.in_page_traffic')"
        :items="[{ name: $t('campaign.targets.in_page_traffic_enable') }]"
        :no-limits="noLimits"
      />
    </v-col>

    <v-col v-if="campaignTrafficPlatformTarget.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card
        :title="$t('campaign.targets.traffic_type')"
        :items="campaignTrafficPlatformTarget"
        :no-limits="noLimits"
        single-target
      />
    </v-col>

    <v-col v-if="targets.trafficQualityTarget.quality" class="pa-1" cols="12" sm="4" lg="3">
      <target-card
        :title="$t('campaign.targets.traffic_type')"
        :items="trafficQualityTarget"
        :no-limits="noLimits"
        single-target
      />
    </v-col>

    <v-col v-if="targets.interestTarget.length" class="pa-1" cols="12" sm="4" lg="3">
      <target-card :title="$t('campaign.targets.interests')" :items="targets.interestTarget" :no-limits="noLimits" />
    </v-col>

    <v-col v-if="campaign.conversionType?.id" class="pa-1" cols="12" md="4" lg="3">
      <target-card :title="$t('campaign.conversion_type')" :items="[campaign.conversionType]" />
    </v-col>

    <v-col v-if="campaign.siteBiddings.length" class="pa-1" cols="12" md="8" lg="6">
      <site-biddings :title="$t('campaign.targets.site_price')" :biddings="campaign.siteBiddings" />
    </v-col>
  </v-row>
</template>

<script>
  import { mapState } from 'vuex'
  import TargetCard from './TargetCard.vue'
  import IpRangeCard from './IpRangeCard.vue'
  import SitesCard from './SitesCard.vue'
  import CpaGoalsCard from './CpaGoalsCard.vue'
  import Campaign from '@/services/classes/Campaign.js'
  import SiteBiddings from './SiteBiddings.vue'

  export default {
    name: 'TargetBlocks',
    components: {
      SiteBiddings,
      CpaGoalsCard,
      SitesCard,
      IpRangeCard,
      TargetCard
    },
    props: {
      campaign: {
        type: Campaign,
        required: true
      },
      noLimits: {
        type: Boolean,
        default: false
      },
      campaignHasTargets: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState('collections', ['connectionTypes']),
      ...mapState('campaigns', ['pricingModels']),
      ...mapState('settings', ['trafficQualityTypesName']),
      blackOrWhiteListsIpRange() {
        return this.targets.ipRangesTarget.accept ? ' Whitelist' : ' Blacklist'
      },
      trafficQualityTarget() {
        const findTrafficQualityTypes = this.trafficQualityTypesName.find(
          ({ value }) => value === this.targets.trafficQualityTarget.quality
        ) || { label: this.targets.trafficQualityTarget.quality }
        return [{ name: findTrafficQualityTypes.label }]
      },
      targets() {
        return this.campaign.targets
      },
      browserTarget() {
        return this.targets.browserTarget.browsers.map((browser) => ({
          ...browser,
          name: browser.version
            ? `${browser.name} (${this.$t('campaign.targets.version')} ≥ ${browser.version})`
            : browser.name
        }))
      },
      operatingSystemTarget() {
        return this.targets.operatingSystemTarget.map((system) => ({
          ...system,
          name: system.version
            ? `${system.name} (${this.$t('campaign.targets.version')} ≥ ${system.version})`
            : system.name
        }))
      },
      spotTarget() {
        return this.targets.spotTarget.spots.map((spot) => ({
          name: spot.tdsId
        }))
      },
      campaignTrafficPlatformTarget() {
        return this.targets.campaignTrafficPlatformTarget.campaignTrafficPlatforms.map(({ name }) => ({
          name
        }))
      },
      carrierTargetType() {
        if (this.targets.carrierTarget.type) {
          const foundConnectionType = this.connectionTypes.find(
            ({ type }) => type === this.targets.carrierTarget.type
          )
          return foundConnectionType ?? { name: this.targets.carrierTarget.type }
        }
        return null
      },
      siteTargetLabel() {
        const { premium, accept } = this.targets.siteTarget

        if (premium === false && accept === true) {
          return this.$t('campaign.targets.white_list')
        }
        if (premium === false && accept === false) {
          return this.$t('campaign.targets.black_list')
        }
        if (premium === true && accept === true) {
          return this.$t('campaign.targets.premium')
        }
        if (premium === true && accept === false) {
          return this.$t('campaign.targets.premium_all')
        }
        return null
      },
      premiumSitesIsAll() {
        return this.targets.siteTarget.premium && !this.targets.siteTarget.accept
      },
      mobileAppTargetLabel() {
        if (this.targets.mobileAppTarget.accept === true) {
          return this.$t('campaign.targets.mobile_apps_white_list')
        }
        if (this.targets.mobileAppTarget.accept === false) {
          return this.$t('campaign.targets.mobile_apps_black_list')
        }
        return null
      },
      pricingModel() {
        const foundPricingModel = this.pricingModels.find(
          ({ value }) => value === this.campaign.pricingModel
        )
        return foundPricingModel ? foundPricingModel.label : this.campaign.pricingModel
      },
      uniquesTargetLabel() {
        if (this.campaign.targets.uniquesTarget.cap && this.campaign.targets.uniquesTarget.ttl) {
          const uniques = this.campaign.targets.uniquesTarget
          return `${this.$t('campaign.uniques_ttl')} ${uniques.cap} ${this.$t('campaign.time')} ${uniques.ttl} ${this.$t(
            'campaign.minutes'
          )}`
        }
        return ''
      }
    }
  }
</script>
