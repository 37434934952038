<template>
  <div class="d-flex align-center flex-wrap">
    <span class="font-weight-medium black--text">{{ !isFirstRule ? $t('campaign.rules.and') : '' }} {{ $t('campaign.rules.if') }}</span>
    <c-select
      :value="rule.field"
      :items="campaignUpdatingRuleConditionFields"
      class="primary--text custom-select"
      :outlined="false"
      hide-details
      height="30"
      background-color="white"
      selection-slot
      item-slot
      @change="updateRule($event, 'field')"
    >
      <template #item="{ item }">
        {{ $t(`campaign.rules.fields.${item}`) }}
      </template>
      <template #selection="{ item }">
        <div class="text-decoration-underline primary--text">
          {{ $t(`campaign.rules.fields.${item}`) }}
        </div>
      </template>
    </c-select>
    <span class="font-weight-medium black--text">{{ $t('campaign.rules.is') }}</span>
    <c-select
      :value="rule.operation"
      :items="campaignUpdatingRuleOperations"
      class="primary--text custom-select"
      hide-details
      :outlined="false"
      height="30"
      background-color="white"
      selection-slot
      item-slot
      @change="updateRule($event, 'operation')"
    >
      <template #item="{ item }">
        {{ $t(`campaign.rules.operations.${item}`) }}
      </template>
      <template #selection="{ item }">
        <div class="text-decoration-underline primary--text">
          {{ $t(`campaign.rules.operations.${item}`) }}
        </div>
      </template>
    </c-select>
    <span class="font-weight-medium black--text mr-2">{{ $t('campaign.rules.than') }}</span>
    <v-sheet width="90">
      <c-text-field
        :value="rule.value"
        type="number"
        height="24"
        hide-details
        :error-messages="errors[`conditions.${currentRuleIndex}.value`]"
        background-color="white"
        border-color="secondary"
        placeholder="100"
        @keypress="integerFilter($event)"
        @input="updateRule($event, 'value')"
        @focus="$emit('clear-error', `conditions.${currentRuleIndex}.value`)"
      />
    </v-sheet>
    <div class="flex align-center">
      <c-close-btn v-if="!isFirstRule || totalConditionsLength > 1" class="ml-2" color="white" width="12" height="12" icon-class="error--text" @click="deleteRule()" />
      <c-btn
        v-if="canAddingCondition"
        icon
        color="primary"
        :icon-props="{
          icon: '$plus',
          size: '16'
        }"
        @click="addRule()"
      />
    </div>
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CCloseBtn from '@clickadilla/components/ui/CCloseBtn.vue'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import { mapState } from 'vuex'

  export default {
    name: 'ConditionItem',
    components: {
      CTextField,
      CSelect,
      CCloseBtn,
      CBtn
    },
    props: {
      currentRuleIndex: {
        type: Number,
        required: true
      },
      canAddingCondition: {
        type: Boolean,
        default: false
      },
      errors: {
        type: Object,
        required: true
      },
      totalConditionsLength: {
        type: Number,
        required: true
      },
      rule: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapState('settings', [
        'maxCampaignUpdatingRuleConditions',
        'campaignUpdatingRuleConditionFields',
        'campaignUpdatingRuleOperations'
      ]),
      isFirstRule() {
        return this.currentRuleIndex === 0
      }
    },
    methods: {
      addRule() {
        this.$emit('add-condition')
      },
      deleteRule() {
        this.$emit('delete-condition', this.currentRuleIndex)
      },
      updateRule(event, field) {
        const data = JSON.parse(JSON.stringify(this.rule))
        data[field] = event
        const index = this.currentRuleIndex
        this.$emit('update-condition', { index, data })
      },
      integerFilter(event) {
        if (!/^-?\d*$/.test(event.key)) event.preventDefault()
      }
    }
  }
</script>

<style scoped lang="scss">
.custom-select ::v-deep .v-select__selections input{
  display: none;
}
</style>
